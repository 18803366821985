import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import videojs from 'video.js';

const StreamWrapper = styled.div`
    background: #334273;
    margin-top: 200px;
    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }    
`;

const CenterInnerStyle = styled.div`
    max-width: 1080px,
    display: flex,
    flex-direction: column,
    position: relative,
    align-tems: stretch,
    margin: 0 auto,
    padding: 10px,
}`;

const playbackUrl = 'https://d1b935ed7ec4.us-east-1.playback.live-video.net/api/video/v1/us-east-1.045084435546.channel.78EmrVvNxiCe.m3u8';

export default function Stream(props) {
    const playerRef = useRef();

    useEffect(() => {
        const player = videojs(playerRef.current, { autoplay: true, muted: true }, () => {
            player.src(playbackUrl);
        });
    
        return () => {
            player.dispose();
        };
    }, []);

    return (
        <StreamWrapper>
            <CenterInnerStyle>
                <div style={playerWrapper}>
                    <div style={aspectSpacer}></div>
                    <div style={FinalWrap}>
                        <video ref={playerRef} style={videoPlayer}></video>
                    </div>
                </div>
            </CenterInnerStyle>
        </StreamWrapper>
    );
}
    const videoPlayer = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0px',
        background: '#000',
        borderRadius: 'var(--radius)',
    }
    const FinalWrap = {
        position: 'absolute !important',
        width: '100%',
        height: '100%',
        top: '0 !important',

    }
    const aspectSpacer = {
        paddingBottom: '56.25%'
    }
    
    const playerWrapper = {
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)',
        backfacevVisibility: 'hidden',
        borderRadius: 'var(--radius)',
        boxShadow: '0 6px 30px rgba(0,0,0,.3)',
        zIndex: '1',
    }