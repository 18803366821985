import React from 'react';
import {
    Nav,
    NavBrand,
    NavItems,
    NavItem,
   // NavItemButton
} from '../Styled/Navbar';

export default function NavbarStyledComponents() {
    return (
        <Nav>
            <NavBrand to="/">TumblerCounter.site</NavBrand>
            <NavItems>
                <NavItem to="/updates">Updates</NavItem>
                <NavItem to="/">Home</NavItem>
            </NavItems>
        </Nav>
    );
}
//<NavItemButton>Register</NavItemButton>
//<NavItemButton primary>Login</NavItemButton>