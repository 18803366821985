import React, { useState, useEffect } from 'react';

//import ReCAPTCHA from 'react-google-recaptcha';

import { SplitImg, Split, SplitTitle } from '../Styled/Split';
import Status from '../components/Status'
import Stream from '../components/Stream'

export default function Home() {
    const [hasError, setErrors] = useState(false);
    const [loading, setLoading] = useState(false)
    const [blocks,setBlocks] = useState([])
    const [captcha,setCaptcha] = useState([])
    //const recaptchaRef = React.createRef();

    if(hasError|| loading|| setCaptcha){}
    
    //function onSubmit () {
    //    const recaptchaValue = recaptchaRef.current.getValue();
    //    setCaptcha(recaptchaValue);
    //}

    function dos () {
        console.log(captcha)
        //alert(captcha); 
        
    }
    
    async function fetchData() {
        //const res = await fetch("/data.json");
        const res = await fetch("https://gy2tgusuw3.execute-api.ap-southeast-2.amazonaws.com/Prod/homepage/");
        // <ReCAPTCHA ref={recaptchaRef} sitekey="6LcotDIUAAAAAMfm_2QOPtbgGYdih_Gu_l9MWXM2" onChange={onSubmit} />  
        
        res
            .json()
            .then(res => setBlocks(res))
            .then(setLoading(false))
            .catch(err => setErrors(err));
        }

    useEffect(() => {
        setLoading(true)
        fetchData();
    }, []);

    return (
        <Split>
            <div>
                <SplitTitle onClick={dos}>{captcha}Tumble Counter</SplitTitle>
                <Status></Status>
                <div dangerouslySetInnerHTML={{__html: blocks}} />
            </div>
            <div>
                <SplitImg src="https://nbn.ddns.net/rock" alt="" />
            </div>
        </Split>
        
    );
}