import React, { useState, useEffect } from 'react';
import tumbler from '../tumbler.png';

export default function ShowStatus() {
    const [hasError, setErrors] = useState(false);
    const [loading, setLoading] = useState(false)
    const [ledger,setLedger] = useState([])

    if(hasError || loading){
        //The old do nothing.
    }
    
    async function fetchData() {
        //const res = await fetch("/data.json");
        const res = await fetch("https://c82sikpekj.execute-api.ap-southeast-2.amazonaws.com/Prod/get/");

        res
            .json()
            .then(res => setLedger(res))
            .then(setLoading(false))
            .catch(err => setErrors(err));
        }

    useEffect(() => {
        setLoading(true)
        fetchData();
    }, []);

    const divStyle = {
        position: 'relative',
        'textAlign': 'center',
    };
    const centered = {
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translate(-50%)'
    };

    return (
        <div>
            <div style={divStyle} >
                <img style={{ alignSelf: 'center' }} src={tumbler} alt="" />
                <div style={centered}>
                </div>
            </div>
            <div>
                <p>Status: {ledger.Status}</p>
                <p>Reason: {ledger.Message}</p>
                <hr />
            </div>
        </div>
    );
}