import React, { useState, useEffect } from 'react';
import {
    BRow,
    ItemCard,
    BlockTitle,
    Features,
    MainImg
} from '../Styled/Updates';
//import { Button } from '../Styled/Button';
const PricingPage = () => {
    const [blocks,setBlocks] = useState([])
    
    useEffect(() => {
        async function fetchData() {
            const res = await fetch("https://api.tumblecounter.site/updates/broken");
            res
                .json()
                .then(res => setBlocks(res))
                .catch(err => '');
        }
        fetchData();
    }, []);

    return (
        <div>
            <h1>Updates</h1>
            <BRow>
                {blocks.map(function(block, index){
                    return (
                        <ItemCard key={index}>
                            <BlockTitle>{block.title}</BlockTitle>
                            <MainImg src={block.image} alt="" />
                            <Features>{block.text}</Features>
                        </ItemCard>
                    )
                })}
            </BRow>
        </div>
    );
}
export default PricingPage;