import styled from 'styled-components';

export const BRow = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding-bottom: 10px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
        display: block;
    }
`;

export const MainImg = styled.img`
        width: 100%;
    
`;

export const ItemCard = styled.div`
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #ccc;
    text-align: center;

    &:hover {
        box-shadow: 3px 3px 5px #ccc;
        transform: translateY(-2px);
    }
`;

export const BlockTitle = styled.h2`
    text-align: center;
    font-weight: 300;
`;

export const Features = styled.ul`
    text-align: left;
    margin: 20px;
    padding: 0;
`;

export const Header = styled.span`
    text-align: center;
    font-size: 54px;
    margin-top: 30px;
    margin-bottom: 60px;
`;
